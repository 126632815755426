import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import HelloCard from "../components/helloCard"

const Hello = () => (
  <Layout>
    <div style={{ padding: "16px 21px", marginTop: "24px" }}>
      <SEO title="Say Hello" />
      <h1 class="center" style={{ marginBottom: "48px" }}>
        Say Hello
      </h1>
      <HelloCard />
    </div>
  </Layout>
)

export default Hello
