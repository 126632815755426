import React from "react"
import "./forms.css"

const HelloCard = () => (
  <div id="form-wrapper">
    <form name="Hello Card" method="POST" data-netlify="true" action="/success">
      <input type="hidden" name="form-name" value="Hello Card" />
      <p>
        <label>
          <input type="text" name="name" placeholder="Name" required />
        </label>
      </p>
      <p>
        <label>
          <input type="email" name="email" placeholder="Email" required />
        </label>
      </p>
      <p>
        <label>
          <input type="phone" name="phone" placeholder="Phone" required />
        </label>
      </p>
      <p>
        <label id="selection">
          <select name="CardType" class="styled-select" required>
            <option value="" disabled selected>
              Choose Card
            </option>
            <option value="I'm New Here">I'm New Here</option>
            <option value="Baptism">Baptism</option>
            <option value="Child Dedication">Child Dedication</option>
            <option value="Join the Team">Join the Team</option>
          </select>
        </label>
      </p>
      <p class="button-wrapper">
        <button type="submit">Send</button>
      </p>
    </form>
  </div>
)

export default HelloCard
